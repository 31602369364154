<template>
  <cp-general-modal
    ref="createModal"
    title="Add Authorized User"
    :ok-disabled="submitDisabled"
    :ok-text="okText"
    :investor-email="investorEmail"
    @onOk="saveChanges"
    @onCancel="resetModalData"
  >
    <div class="d-flex justify-content-between">
      <div class="w-100 mr-3">
        <cp-input
          v-model.trim="email"
          :disabled="submitDisabled"
          name="email"
          label="Email"
          :error="responseError"
          validate="required|sid-email"
          placeholder="e.g jamie@gmail.com"
        />
        <cp-select
          key="role"
          v-model="role"
          name="role"
          label="Role"
          class="mt-1"
          input-class="text-capitalize"
          :options="authorizedAccountsRoles()"
        />
        <div
          v-if="isInvestorMissingAnOwner"
          class="mt-2 warning-text"
        >
          <span class="mr-1">*</span>
          <span>Please advise, this entity investor does not have a securitize-id Owner, the first authorized account add will be the Owner of this entity investor</span>
        </div>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { CpSelect, CpInput } from '@/components/common/standalone-components/inputs';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'AuthorizedAccountModel',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  props: {
    authorizedInvestors: {
      type: Array,
      default: () => [],
    },
    investorEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      role: '',
      submitDisabled: false,
      responseError: '',
      okText: 'Add',
      isFirstLoad: true,
    };
  },
  computed: {
    isInvestorMissingAnOwner() {
      return !this.authorizedInvestors.length;
    },
  },
  updated() {
    if (this.isFirstLoad) {
      this.setRole();
      this.isFirstLoad = false;
    }
  },
  methods: {
    ...mapActions('securitizeId', ['sendSecuritizeIdAuthorizedAccountRequest']),
    resetModalData() {
      this.email = '';
      this.okText = 'Add';
      this.setRole();
    },
    show() {
      this.responseError = '';
      this.$refs.createModal.show();
    },
    validateEmail() {
      const isValidEmail = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(this.email);
      if (!isValidEmail || this.email.length > 256) {
        this.responseError = 'Please enter a valid email';
        return;
      }
      this.responseError = '';
    },
    saveChanges() {
      const { email: existingAuthorizedEmail, status } = this.authorizedInvestors.find(auth => auth.email === this.email.toLocaleLowerCase()) || {};
      const isTheEntityEmail = this.investorEmail.toLocaleLowerCase() === this.email.toLocaleLowerCase();
      const isEmailAlreadyInvited = existingAuthorizedEmail || isTheEntityEmail;

      if (isEmailAlreadyInvited) {
        if (status === 'owner') this.responseError = 'This email is the account owner';
        if (status === 'pending') this.responseError = 'This email is already pending';
        if (status === 'approved') this.responseError = 'This email is already approved';
        if (isTheEntityEmail) this.responseError = 'This is the entity email';
        return;
      }

      this.submitDisabled = true;
      this.okText = 'Adding...';

      this.sendSecuritizeIdAuthorizedAccountRequest({
        email: this.email,
        role: this.role === 'owner' ? 'admin' : this.role,
        investorId: this.$route.params.investorId,
      })
      .then((data) => {
        if (data && data.response && data.response.status === 404) {
           this.responseError = 'Sorry, it seems that the email you entered is not yet associated with a Securitize ID account. Please make sure the person you wish to add has a Securitize ID account and try again';
        } else {
          this.$refs.createModal.hide();
          this.responseError = '';
          this.$emit('updateAuthorizedAccountTable');
          this.$toast.open({
            message: 'Email sent',
            duration: 5000,
          });
        }
      })
      .finally(() => {
        this.submitDisabled = false;
        this.resetModalData();
      });
    },
    authorizedAccountsRoles() {
      return this.isInvestorMissingAnOwner
        ? [
            { value: 'owner', text: 'Owner', disabled: false },
            { value: 'admin', text: 'Authorized Signer', disabled: true },
            { value: 'viewer', text: 'Viewer', disabled: true },
          ]
        : [
            { value: 'admin', text: 'Authorized Signer', disabled: false },
            { value: 'viewer', text: 'Viewer', disabled: false },
          ];
    },
    setRole() {
      this.role = this.isInvestorMissingAnOwner ? 'owner' : 'admin';
      return this.role;
    },
  },
};
</script>
<style lang="scss" scoped>
  .warning-text {
    display: flex;
    color: red;
  }
</style>
