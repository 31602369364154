<template>
  <div :key="componentKey">
    <div
      class="card mb-5"
      :class="updateInProcess?'sec-saving':''"
    >
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ stockExchangeRegulation }}
            </h4>
          </div>
          <cp-edit-buttons
            :edit-mode="isDetailsEditableCurrentBlock"
            :disable-edit="isEditButtonDisabled"
            @changeMode="toggleEditMode"
            @update="save"
          />
        </div>
      </div>
      <div class="card-body">
        <b-alert
          variant="danger"
          :show="infoMissing && getMissingFields"
        >
          {{ getMissingFields }}
        </b-alert>
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div class="status-current">
            <div class="text-muted small">
              {{ $t('investorDetails.kyc.label.currentRegulationStatus', [stockExchangeRegulation]) }}
            </div>
            <cp-editable-input
              v-model="model.verificationStatus"
              class="mb-1"
              :edit-mode="isDetailsEditableCurrentBlock"
              :text="kycStatusName"
              :options="verificationStatuses"
              :text-class="getStatusColor"
            />
            <div
              v-if="isEntity"
              class="custom-control custom-checkbox pl-4 text-muted small"
            >
              <input
                id="is-enhance-requested"
                v-model="model.isEnhanceRequested"
                class="custom-control-input"
                type="checkbox"
                :disabled="!isDetailsEditableCurrentBlock || (model.verificationStatus && model.verificationStatus === 'enhance-verified')"
              >
              <label
                for="is-enhance-requested"
                class="custom-control-label"
              >
                {{ $t('investorDetails.kyc.label.enhanceRequired') }}
              </label>
            </div>
          </div>
          <div class="my-1 ml-2">
            <img
              v-if="model.registrationSource === 'OFN'"
              class="registration-source-image"
              src="/src/assets/img/logo-openfinance.png"
              :title="model.registrationSource"
              alt="open-finance"
            >
            <img
              v-if="model.registrationSource && model.registrationSource !== 'OFN'"
              class="registration-source-image"
              :src="model.registrationSource"
              :title="model.registrationSource"
              alt="open-finance"
            >
          </div>
          <div class="col-md-5 my-1">
            <div class="progress">
              <div
                :class="getStatusBarColor"
                :style="{ width: getProgressBarPercent + '%', }"
              >
                {{ getProgressBarPercent }}%
              </div>
            </div>
          </div>
          <div
            class="my-1 d-flex justify-content-end"
          >
            <cp-button
              v-click-outside="hideError"
              class="mr-2"
              variant="outline-primary"
              :disabled="!applicantId"
              @click="openSumSubReport"
            >
              {{ $t('securitizeId.kyc.label.viewExternalReport') }}
            </cp-button>
            <cp-button
              v-if="allowCheckRerun"
              v-click-outside="hideError"
              variant="outline-primary"
              icon="ios-finger-print"
              :is-loading="kycCheckWorking"
              @click="runRegulationCheck"
            >
              {{ $t(`${noneStatus ? 'securitizeId.kyc.label.runRegulationCheck' : 'securitizeId.kyc.label.rerunRegulationCheck'}`, [stockExchangeRegulation]) }}
            </cp-button>
            <sync-to-sumsub
              v-if="isEntity && canSeeSyncToSumsubBtn"
              v-click-outside="hideError"
              :is-loading="kycCheckWorking"
              @onAccept="runRegulationCheck"
            />
            <div
              v-if="isEntity"
              class="ml-2"
            >
              <link-verification />
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="d-flex justify-content-between align-items-center">
            <p class="m-0">
              {{ $t('investorDetails.kyc.label.errors') }}
            </p>
            <cp-button
              v-click-outside="hideError"
              variant="primary"
              :disabled="!idologyCheckId"
              @click="openIdologyReport"
            >
              {{ $t('securitizeId.kyc.label.viewExternalReport') }}
            </cp-button>
          </div>
          <div class="kyc-errors-container">
            <span
              v-for="error in kycErrors"
              :key="error"
            >
              {{ getVerificationText(error) }}
            </span>
            <strong v-if="!kycErrors.length"> - </strong>
          </div>
        </div>
        <hr
          v-if="((isDetailsEditableCurrentBlock && model.verificationStatus === 'updates-required')
            || getGeneralInfo.verificationStatus === 'updates-required')
            && isEntity"
        >
        <cp-require-updates
          v-if="((isDetailsEditableCurrentBlock && model.verificationStatus === 'updates-required')
            || getGeneralInfo.verificationStatus === 'updates-required')
            && isEntity"
          :is-edit="isRequiredUpdatesEdit"
          @updateRequiredChange="onUpdateRequiredChange"
          @documentsUpdated="() => $emit('documentsUpdated')"
        />
        <hr>
        <div class="d-flex">
          <div class="d-flex flex-column flex-1 mr-3">
            <div class="d-flex justify-content-between align-items-center p-3 pr-lg-5 bg-grey">
              <span>
                {{ $t('investorDetails.kyc.label.lastRegulationChange', [stockExchangeRegulation]) }}
              </span>
              <span>
                <strong>
                  {{ kycLastChangeDate | dateFilter }}
                </strong>
              </span>
            </div>
            <div class="mx-3" />
            <div class="d-flex justify-content-between align-items-center p-3 pr-lg-5 bg-grey mt-3">
              <span>
                {{ $t('investorDetails.kyc.label.expirationDate', ['ID']) }}
              </span>
              <span>
                <strong>
                  {{ kycStatusExpiryDate | dateFilter }}
                </strong>
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-center p-3 pr-lg-5 bg-grey mt-3 fit-content">
              <span>
                {{ $t('investorDetails.kyc.label.transactionId', [stockExchangeRegulation]) }}
              </span>
              <span>
                {{ getTransactionId }}
              </span>
            </div>
          </div>
          <div class="d-flex flex-column flex-1">
            <div class="d-flex flex-column p-3 bg-grey">
              <span class="mb-3 font-weight-bold">
                {{ $t('investorDetails.kyc.label.kycStatusLog', [stockExchangeRegulation]) }}
              </span>
              <cp-securitize-id-kyc-log-table
                ref="kycLogTable"
                :key="kycStatusName"
                :investor-id="getGeneralInfo.investorId"
                @historyUpdated="onHistoryUpdated"
              />
            </div>
          </div>
        </div>
        <hr>
        <cp-comments-log
          v-if="doDisplayComments"
          v-model="model.kycComment"
          :is-editable="isDetailsEditableCurrentBlock"
          :comments-array="commentsArray"
        />
      </div>
    </div>
    <!--View KYC Report modal-->
    <b-modal
      v-model="previewOpen"
      size="lg"
      class="cp-preview-modal"
      ok-only
      centered
    >
      <div
        slot="modal-header"
        style="width:100%; text-align: left"
      >
        <h5 class="modal-title d-flex justify-content-between">
          {{ $t('investorDetails.generalPropertiesModal.title') }}
        </h5>
      </div>
      <iframe
        id="preview-kyc-iframe"
        :style="previewHtml ? 'height:700px;width:100%;' : 'height:700px;width:100%;display:none'"
        frameborder="0"
      />
      <div class="d-flex justify-content-center align-items-center">
        <Spinner
          v-if="!previewHtml"
          name="wave"
          color="#04bec4"
          fade-in="quarter"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Spinner from 'vue-spinkit';
import ClickOutside from 'vue-click-outside';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import CpEditButtons from '~/components/common/edit-buttons';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpCommentsLog from '~/components/common/standalone-components/cp-comments-log';
import CpEditableInput from '~/components/common/editable-input';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpSecuritizeIdKycLogTable from '@/pages/securitize-id/components/kyc-log-table';
import LinkVerification from '@/pages/securitize-id/components/link-verification';
import SyncToSumsub from '@/pages/securitize-id/components/sync-to-sumsub';
import CpRequireUpdates from '@/pages/securitize-id/components/require-updates';
import { kyc, kyb, updatesRequiredTypes, filterLists } from './options';
import documents from './details/documents/options';


export default {
  name: 'CpSecuritizeIdInvestorDetailsKyc',
  components: {
    CpSecuritizeIdKycLogTable,
    LinkVerification,
    SyncToSumsub,
    Spinner,
    CpEditableInput,
    CpEditButtons,
    CpButton,
    CpCommentsLog,
    CpRequireUpdates,
  },
  directives: {
    ClickOutside,
  },
  mixins: [
    CpEditableMixin('kyc', 'securitizeId'),
  ],
  data() {
    return {
      model: {
        isEnhanceRequested: false,
        verificationStatus: '',
      },
      componentKey: 0,
      previewOpen: false,
      previewHtml: false,
      gettingReport: false,
      kycCheckWorking: false,
      kycEmailWorking: false,
      infoMissing: false,
      frontDoc: false,
      canSeeSyncToSumsubBtn: false,
      updateInProcess: false,
      kycReportWorking: false,
      kycAccreditation: kyc.accreditation,
      generalFields: {
        gender: this.$t('investorDetails.kyc.field.general.gender'),
        birthdate: this.$t('investorDetails.kyc.field.general.birthdate'),
        address1: this.$t('investorDetails.kyc.field.general.address1'),
        zipCode: this.$t('investorDetails.kyc.field.general.zipCode'),
        city: this.$t('investorDetails.kyc.field.general.city'),
      },
      applicantId: null,
      idologyCheckId: null,
      kycStatusExpiryDate: null,
      kycLastChangeDate: null,
      requiredUpdates: {
        [updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO]: {},
        [updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]: [],
        [updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS]: false,
        [updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT]: [],
      },
      documentCategories: documents.categories,
      kycErrors: [],
      availableKycErrors: filterLists.verificationErrors,
    };
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    ...mapGetters('currentOperator', ['operatorData']),
    isEntity() {
      return this.getGeneralInfo.investorType === 'entity';
    },
    verificationStatuses() {
      return this.isEntity ? kyb.statuses : kyc.statuses;
    },
    noneStatus() {
      return this.getGeneralInfo.verificationStatus === 'none';
    },
    stockExchangeRegulation() {
      return this.isEntity ? 'KYB' : 'KYC';
    },
    getMissingFields() {
      return null;
    },
    getTransactionId() {
      return this.getGeneralInfo.transactionId || '-';
    },
    commentsArray() {
      return [...(this.getGeneralInfo.kycComments || [])].reverse().map(comment => comment.split('\n')).flat();
    },
    allowCheckRerun() {
      return this.getGeneralInfo.verificationStatus !== 'processing'
              && this.getGeneralInfo.investorType !== 'entity';
    },
    getStatusColor() {
      switch (this.getGeneralInfo.verificationStatus) {
        case 'none':
          return 'badge badge-grey';
        case 'processing':
        case 'updates-required':
        case 'expired':
          return 'badge badge-orange';
        case 'verified':
          return 'badge badge-green';
        case 'manual-review':
          return 'badge badge-yellow';
        case 'rejected':
          return 'badge badge-red';
        default:
          return 'badge badge-grey';
      }
    },
    getStatusBarColor() {
      const styleBase = 'progress-bar';
      switch (this.getGeneralInfo.verificationStatus) {
        case 'none':
        case 'processing':
        case 'updates-required':
        case 'manual-review':
        case 'review':
        case 'expired':
          return `${styleBase} bg-warning`;
        case 'verified':
          return `${styleBase} bg-success`;
        case 'rejected':
        default:
          return `${styleBase} bg-danger`;
      }
    },
    kycStatusName() {
      const kycCalculatedStatus = this.calculateVerificationStatus();
      const kycStatus = this.verificationStatuses.find(
          ({ value }) => value === kycCalculatedStatus,
      );

      return kycStatus
        ? this.$t(kycStatus.i18nKey)
        : kycCalculatedStatus && (kycCalculatedStatus.charAt(0).toUpperCase() + kycCalculatedStatus.slice(1)).replaceAll('-', ' ');
    },
    getProgressBarPercent() {
      switch (this.getGeneralInfo.verificationStatus) {
        case 'none':
          return 0;
        case 'processing':
        case 'updates-required':
        case 'manual-review':
        case 'review':
        case 'expired':
          return 50;
        case 'verified':
        case 'rejected':
          return 100;
        default:
          return 0;
      }
    },
    doDisplayComments() {
      return (this.getGeneralInfo && this.getGeneralInfo.kycComments && !!this.getGeneralInfo.kycComments.length) || this.isDetailsEditableCurrentBlock;
    },
    isRequiredUpdatesEdit() {
      return this.isDetailsEditableCurrentBlock && (this.model.verificationStatus === 'updates-required');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'model.verificationStatus': function (newValue) {
      if (newValue === 'enhance-verified') {
        this.model.isEnhanceRequested = false;
      }
    },
    isRequiredUpdatesEdit(newVal) {
      if (!newVal) {
        this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION] = [];
      }
    },
  },
  beforeMount() {
    this.model.isEnhanceRequested = this.getGeneralInfo.verificationSubStatus === 'enhance-requested';
    if (this.getGeneralInfo.investorType === 'entity') {
      this.getInvestorKycHistory({ params: { investorId: this.getGeneralInfo.investorId } })
          .then(({ data }) => {
            const result = data.some(({ status }) => status === 'verified');
            this.canSeeSyncToSumsubBtn = !result;
          });
    }
  },
  methods: {
    ...mapActions('securitizeId', ['updateSecuritizeIdInvestorVerificationStatus',
      'performSecuritizeIdInvestorManualCheck', 'updateSecuritizeIdInvestorByInvestorId',
      'getSecuritizeIdInvestorByInvestorId', 'updateInvestorDocuments']),
    ...mapActions('brokerDealerInvestors', ['getInvestorKycHistory']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    hideError() {
      this.infoMissing = false;
    },
    calculateVerificationStatus() {
      const blocked = this.getGeneralInfo.verificationStatus === 'rejected'
          && this.getGeneralInfo.verificationSubStatus === 'blocked'
          && 'rejected-blocked';
      const documentsExpired = this.getGeneralInfo.verificationStatus === 'verified'
          && this.getGeneralInfo.verificationSubStatus === 'documents-expired'
          && 'verified-documents-expired';
      const expired = this.getGeneralInfo.verificationStatus === 'verified'
          && this.getGeneralInfo.verificationSubStatus === 'expired'
          && 'verified-expired';
      const enhanceVerified = this.getGeneralInfo.verificationStatus === 'verified'
          && this.getGeneralInfo.verificationLevel === 'enhanced'
          && 'enhance-verified';

      return blocked || documentsExpired || expired || enhanceVerified || this.getGeneralInfo.verificationStatus;
    },
    calculateVerificationStatusAndSubStatus() {
      const blocked = this.model.verificationStatus === 'rejected-blocked'
          && { verificationStatus: 'rejected', verificationSubStatus: this.model.isEnhanceRequested ? 'enhance-requested' : 'blocked' };
      const documentsExpired = this.model.verificationStatus === 'verified-documents-expired'
          && { verificationStatus: 'verified', verificationSubStatus: this.model.isEnhanceRequested ? 'enhance-requested' : 'documents-expired' };
      const expired = this.model.verificationStatus === 'verified-expired'
          && { verificationStatus: 'verified', verificationSubStatus: this.model.isEnhanceRequested ? 'enhance-requested' : 'expired' };
      const enhanceVerified = this.model.verificationStatus === 'enhance-verified'
          && { verificationStatus: 'verified', verificationSubStatus: 'none', verificationLevel: 'enhanced' };
      const enhanceRequested = this.model.isEnhanceRequested && this.getGeneralInfo.verificationSubStatus !== 'enhance-requested' && this.model.verificationStatus !== 'enhance-verified'
          && { verificationStatus: this.model.verificationStatus, verificationSubStatus: 'enhance-requested' };
      const theRest = { verificationStatus: this.model.verificationStatus, verificationSubStatus: this.model.isEnhanceRequested ? 'enhance-requested' : 'none' };

      return blocked || documentsExpired || expired || enhanceVerified || enhanceRequested || theRest;
    },
    toggleEditMode() {
      this.changeMode();
      this.updateInProcess = false;
      const verificationStatus = this.calculateVerificationStatus();
      this.model = {
        ...this.getGeneralInfo,
        verificationStatus,
        isEnhanceRequested: this.getGeneralInfo.verificationSubStatus === 'enhance-requested',
      };
    },
    runRegulationCheck() {
      this.kycCheckWorking = true;
      this.performSecuritizeIdInvestorManualCheck({ investorId: this.getGeneralInfo.investorId })
        .then(() => {
          this.getSecuritizeIdInvestorByInvestorId({
            investorId: this.getGeneralInfo.investorId,
          });
        })
        .finally(() => {
          this.updateInProcess = false;
          this.kycCheckWorking = false;
          this.rerender();
        });
    },
    save() {
      if (this.updateInProcess) {
        return;
      }
      this.updateInProcess = true;
      const promises = [];

      const { verificationStatus, verificationSubStatus, verificationLevel } = this.calculateVerificationStatusAndSubStatus();

      const kycReq = {
        newStatus: verificationStatus,
        newSubStatus: verificationSubStatus,
        newVerificationLevel: verificationLevel || 'standard',
      };

      const verificationLevelChanged = this.getGeneralInfo.verificationLevel !== verificationLevel && !!verificationLevel;

      const isKycChanged = this.getGeneralInfo.verificationStatus !== kycReq.newStatus
          || this.getGeneralInfo.verificationSubStatus !== kycReq.newSubStatus
          || verificationLevelChanged;

      const isAdditionalDocumentsRequired = !_.isEqual(
                (this.getGeneralInfo.requestedUpdates || [])
                  .filter(req => req.updateType === 'additional-documentation')
                  .map(req => req.updateDocType),
                this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION].map(doc => doc.docType),
              );

      if (isKycChanged || isAdditionalDocumentsRequired) {
        promises.push(this.updateSecuritizeIdInvestorVerificationStatus({
          ...kycReq,
          investorId: this.getGeneralInfo.investorId,
          errors: this.model.verificationStatus === 'updates-required'
            ? this.getErrorMessages() : [],
        }));
      }
      promises.push(this.updateRequiredDocumentStatus());

      const requestedUpdates = this.getUpdatesRequiredMessages(this.getRequestedUpdatesItem);

      let kycComment = {};
      if (this.getGeneralInfo.kycComment !== this.model.kycComment || requestedUpdates.length) {
        const timestamp = new Date().toISOString();

        const kycComments = [
          ...this.getGeneralInfo.kycComments || [],
        ];

        if (this.model.kycComment) {
          const comment = `${this.operatorName()} at ${moment(timestamp).format('hh:mm MMM D,YYYY')}: ${this.model?.kycComment.includes('\n') ? `\n${this.model?.kycComment}` : this.model.kycComment}`;
          kycComments.push(comment);
        }

        if (requestedUpdates.length) {
          const commentForInvestor = `${this.operatorName()} at ${moment(timestamp).format('hh:mm MMM D,YYYY')} [Updates Required Message]: ${requestedUpdates[0].updateName}`;
          kycComments.push(commentForInvestor);
        }

        kycComment = {
          ...this.getGeneralInfo,
          kycComments,
          lastVerificationCommentedBy: this.operatorName(),
          lastVerificationCommentedTime: timestamp,
          investorType: this.model.investorType,
        };
      }

      promises.push(this.updateSecuritizeIdInvestorByInvestorId({
        investorId: this.getGeneralInfo.investorId,
        info: {
          ...kycComment,
          requestedUpdates,
        },
      }));

      if (promises.length) {
        Promise.all(promises).then(() => {
          this.getSecuritizeIdInvestorByInvestorId({
            investorId: this.getGeneralInfo.investorId,
          }).then((res) => {
            this.toggleEditMode();
            if (isAdditionalDocumentsRequired) {
              this.kycErrors = res.data.requestedUpdates.map(req => req.updateName);
            }
          });
        }).finally(() => {
          this.requiredUpdates = {
            [updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO]: {},
            [updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]: [],
            [updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT]: [],
            [updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS]: false,
          };
        });
      } else {
        this.toggleEditMode();
      }
    },
    rerender() {
      this.componentKey += 1;
    },
    openSumSubReport() {
      if (this.applicantId) {
        window.open(`https://api.sumsub.com/checkus#/applicant/${this.applicantId}`, '__blank');
      }
    },
    openIdologyReport() {
      if (this.idologyCheckId) {
        window.open(`https://web.idologylive.com/reports/query.php?id=${this.idologyCheckId}`, '__blank');
      }
    },
    onUpdateRequiredChange({ updateType, data }) {
      if (data.docType) {
        if (data.signerId && !this.requiredUpdates[updateType][data.signerId]) {
          this.requiredUpdates[updateType][data.signerId] = [];
        }
        if (data.signerId) this.addRequiredChangeToSigner(updateType, data);
        else this.addRequiredChange(updateType, data);
      } else {
        this.requiredUpdates[updateType] = data;
      }
    },
    addRequiredChange(updateType, data) {
      const index = this.requiredUpdates[updateType].findIndex(item => (data.docId ? item.docId === data.docId : item.docType === data.docType));
      if (index !== -1) {
        if (data.checked) {
          this.requiredUpdates[updateType].splice(index, 1, data);
        } else {
          this.requiredUpdates[updateType].splice(index, 1);
        }
      } else {
        this.requiredUpdates[updateType] = [...this.requiredUpdates[updateType], data];
      }
    },
    addRequiredChangeToSigner(updateType, data) {
      const index = this.requiredUpdates[updateType][data.signerId].findIndex(item => item.docId === data.docId);
      if (index !== -1) this.requiredUpdates[updateType][data.signerId].splice(index, 1, data);
      else this.requiredUpdates[updateType][data.signerId] = [...this.requiredUpdates[updateType][data.signerId], data];
    },
    getUpdatesRequiredMessages(getErrorItem) {
      const errors = [];
      const reduceErrors = updateType => (accum, item) => (item.checked
        ? [...accum, getErrorItem(item, updateType)] : accum);

    this.requiredUpdates[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT].forEach((item) => {
      errors.push(getErrorItem(item, updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT));
    });
      const additionalLegalSigners = this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS];
      if (additionalLegalSigners && additionalLegalSigners.checked) {
        errors.push(getErrorItem({}, updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS));
      }
      errors.push(...this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]
        .reduce(reduceErrors(updatesRequiredTypes.ADDITIONAL_DOCUMENTATION), []));

      const errorsBySigner = Object.keys(this.requiredUpdates[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO])
        .reduce((accum, key) => [...accum,
          this.requiredUpdates[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO][key]
            .reduce(reduceErrors(updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO), [])], []);

      errors.push(...errorsBySigner.reduce((accum, item) => [...accum, ...item], []));

      return errors;
    },
    getErrorMessages() {
      const errors = [];
      const additionalLegalSigners = this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS];
      if (additionalLegalSigners && additionalLegalSigners.checked) {
        errors.push({
          errorKey: 'additional-legal-signers-needed',
          errorMessage: `More legal signers are needed for ${this.getGeneralInfo.investorId}`,
        });
      }
      if (this.requiredUpdates[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT].length) {
        this.requiredUpdates[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT]
          .forEach((errorMessage) => {
          errors.push({ errorKey: 'free-text', errorMessage });
        });
      }

      const hasRequiredMoreDocumentation = this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]
        .some(item => item.checked);

      if (hasRequiredMoreDocumentation) {
        this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION].map(item => errors.push({
            errorKey: item.docType,
            errorMessage: `More documentation is needed for entity ${this.getGeneralInfo.investorId}`,
          }));
      }

      const hasRequestedMoreLegalSignerDocumentation = !!Object.keys(
        this.requiredUpdates[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO],
      )
        .reduce((accum, key) => [...accum,
          ...this.requiredUpdates[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO][key]
            .filter(({ docId, checked }) => !!docId && checked)], []).length;

      if (hasRequestedMoreLegalSignerDocumentation) {
        errors.push({
          errorKey: 'additional-documentation-for-legal-signer-needed',
          errorMessage: `More documentation is needed for some legal signers of entity ${this.getGeneralInfo.investorId}`,
        });
      }

      return errors;
    },
    getRequestedUpdatesItem(item = {}, updateType) {
      const investorType = item.investorType || this.getGeneralInfo.investorType;
      const isTextType = updateType === updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT;
      return {
        updateType: updateType.replaceAll('_', '-').toLowerCase(),
        legalSignerName: item.signerName,
        legalSignerId: item.signerId,
        updateName: isTextType ? item : item && this.getDocumentName(item.docType, investorType, item.entityType),
        updateDocType: item.docType,
      };
    },
    getDocumentName(docType, investorType, entityType) {
      const documentsByType = investorType === 'individual'
        ? this.documentCategories.documentTypeIndividual
        : this.documentCategories.documentsTypeEntity[entityType || this.getGeneralInfo.entityType];
      const found = documentsByType.find(({ value }) => value.docType === docType);
      if (!found) return docType;
      return found.text;
    },
    updateRequiredDocumentStatus() {
      const additionalDocumentsData = this.requiredUpdates[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]
        .filter(({ docId, checked }) => !!docId && checked)
        .map(({ docId, docType }) => ({ documentId: docId, docType, verificationStatus: 'not-verified' }));

      const allSignersDocuments = Object.keys(this.requiredUpdates[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO])
        .reduce((accum, key) => [...accum,
          ...this.requiredUpdates[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO][key]
            .filter(({ docId, checked }) => !!docId && checked)
            .map(({ docId }) => ({ documentId: docId, verificationStatus: 'not-verified' }))], []);

      const docs = [...additionalDocumentsData, ...allSignersDocuments];
      return docs.length ? this.updateInvestorDocuments({
        investorId: this.getGeneralInfo.investorId,
        documents: docs,
      }) : null;
    },
    getVerificationText(error) {
      const translatedMessage = this.$t(`securitizeId.kyc.errors.${error.replaceAll('.', '-')}`);
      if (translatedMessage) return translatedMessage;
      const found = this.availableKycErrors.find(item => item.value === error);
      return found ? found.text : error;
    },
    onHistoryUpdated(data) {
      const applicant = data.find(item => !!item.applicantId) || {};
      const idology = data.find(item => !!item.idologyCheckId) || {};
      this.applicantId = applicant.applicantId;
      this.idologyCheckId = idology.idologyCheckId;

      const latest = data.reduce((latestItem, item) => (latestItem && latestItem.date > item.date ? latestItem
        : item), null);
      this.kycStatusExpiryDate = latest.expirationDate;
      this.kycLastChangeDate = latest.date;
      this.kycErrors = latest.verificationErrors;
    },
    formatDate(date) {
      return moment(date).format('HH:mm DD-MM-YYYY');
    },
    operatorName() {
      return this.operatorData.viewData.getName ? this.operatorData.viewData.getName() : this.operatorData.name;
    },
  },
};
</script>

<style lang="scss" scoped>
    .cp-preview-modal {
        text-align: center;

        iframe {
            width: 100%;
            height: 700px;
        }
    }

    .bg-grey {
        background-color: rgba(10, 23, 39, 0.025)
    }

    .registration-source-image {
        max-height: 30px;
    }

    .status-current {
        min-width: 15%;
    }
    .sec-saving::after{
      content:"";
      position: absolute;
      width:100%;
      height:100%;
      background: #fff;
      opacity: 0.4;
    }

    .fit-content {
      height: fit-content;
    }

    .flex-1 {
      flex: 1;
    }

    .kyc-errors-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 1em;
      width: 90%;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #617d8d;
        margin-bottom: 0.5em;
        width: 100%;
      }

      span {
        border-radius: 2px;
        background-color: #ff5c78;
        padding: 0.5em;
        color: #fff;
        font-size: 10px;
        font-weight: 500;
        margin: 0 0.5em 0.5em 0;
        line-height: 1.11;
      }
    }
</style>
<style lang="scss">
  .badge-grey {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
    box-shadow: 0 0 0 1px #dee0e2 inset;
    color: #dee0e2
  }
  .badge-orange {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
    box-shadow: 0 0 0 1px #F5A01D inset;
    color: #F5A01D
  }
  .badge-yellow {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
    box-shadow: 0 0 0 1px #ffd950 inset;
    color: #ffd950
  }
  .badge-green {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
    box-shadow: 0 0 0 1px #07bc77 inset;
    color: #07bc77
  }
  .badge-red {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px #f7321f inset;
    box-shadow: 0 0 0 1px #f7321f inset;
    color: #f7321f
  }
</style>
