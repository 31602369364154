<template>
  <div class="row mt-4">
    <div class="comments-box col-12">
      <div class="card bg-light">
        <div class="card-header d-flex flex-column justify-content-between">
          <h6 class="mb-0">
            Comments
          </h6>
          <div
            v-if="isEditable"
            class="mt-3"
          >
            <cp-textarea
              :placeholder="'Add your comment here...'"
              :input-class="'comment-input'"
              :rows="2"
              @input="$emit('input', $event)"
            />
          </div>
        </div>
        <div class="card-body">
          <div>
            <div
              v-for="(comment, index) in commentsArray"
              :key="index"
              class="mb-3"
            >
              <pre class="comment">{{ comment }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpTextarea from './inputs/cp-textarea.vue';

export default {
  name: 'CpCommentsLog',
  components: {
    CpTextarea,
  },
  props: {
    commentsArray: {
      type: Array,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
  .comments-box {
    font-size: 1rem;

    .card-header {
      border: none;
      padding: 1rem;
    }
    .card-body {
      padding: 0 1rem;
      max-height: 21vh;
      overflow-y: auto;
    }
    .comment-input {
      padding: 21px 16px;
    }
    .comment {
      font-family: inherit;
      font-size: 0.894rem;
      font-weight: 400;
      line-height: 1.47;
      color: #627d8e;
    }
  }
</style>
