<template>
  <div>
    <cp-button
      variant="outline-primary"
      icon="ios-finger-print"
      @click="openModal"
    >
      Link to Applicant
    </cp-button>
    <b-modal
      ref="link-modal"
      size="lg"
      :ok-disabled="okDisabled"
      centered
      @ok="onOk"
      @cancel="clearModal"
      @show="clearModal"
    >
      <div
        slot="modal-header"
        style="width:100%; text-align: left"
      >
        <h5 class="modal-title d-flex justify-content-between">
          Link to Applicant
        </h5>
      </div>
      <div>
        <div class="w-100">
          <cp-input
            v-model="applicantUrl"
            placeholder="https://cockpit.sumsub.com/checkus/#/applicant/6576f250b911624b31e5ff59/basicInfo"
            :error="validateApplicantUrl"
            name="regex"
            :disabled="isLinkModalLoading"
            label="Sumsub Applicant URL"
            class="w-100"
          />
          <div
            v-if="!applicantUrl"
            class="mt-1 font-85"
          >
            Please enter the URL on the Sumsub dashboard of the applicant page you wish to link.
          </div>
        </div>
        <div
          v-if="!!linkModalData"
          class="mt-4"
        >
          <div v-if="futureApplicantId === getGeneralInfo.investorId">
            This applicant is already linked
          </div>
          <div v-else>
            You are about to link {{ futureApplicantName }} - {{ futureApplicantId }} with this Securitize ID account: {{ entityName }} -
            {{ getGeneralInfo.investorId }}.
          </div>

          <div
            v-if="!!currentLinkedInvestorData"
            class="mt-2"
          >
            *This applicant is already linked with the Securitize ID account:
            {{ currentLinkedInvestorData.entityDba }} - {{ currentLinkedInvestorData.investorId }}. Linking to a new investor will unlink the applicant from the old investor.
          </div>
        </div>

        <div class="d-flex align-content-center justify-content-center">
          <Spinner
            v-if="isLinkModalLoading"
            name="wave"
            class="mt-4"
            color="#04bec4"
            fade-in="quarter"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Spinner from 'vue-spinkit';
import ClickOutside from 'vue-click-outside';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CpButton from '@/components/common/standalone-components/cp-button';
import { CpInput } from '@/components/common/standalone-components/inputs';

export default {
  name: 'LinkVerification',
  components: {
    CpButton,
    CpInput,
    Spinner,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      applicantUrl: '',
      isLinkModalLoading: false,
      linkModalData: null,
      currentLinkedInvestorData: null,
    };
  },
  watch: {
    applicantId: {
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.getVerificationLinkData();
        }
        if (!val) {
          this.linkModalData = null;
          this.currentLinkedInvestorData = null;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    futureApplicantName() {
      return this.linkModalData?.applicantData?.name?.trim() || '-';
    },
    futureApplicantId() {
      return this.linkModalData?.applicantData?.externalId;
    },
    entityName() {
      return this.getGeneralInfo.entityDba || '-';
    },
    okDisabled() {
      return this.isLinkModalLoading || !this.applicantId || this.futureApplicantId === this.getGeneralInfo.investorId;
    },
    validateApplicantUrl() {
      if (!this.applicantUrl) return '';
      const regex = /\/applicant\/([a-f\d]+)\/basicInfo/;
      const match = this.applicantUrl.match(regex);
      return match ? '' : 'Invalid URL';
    },
    applicantId() {
      if (!this.applicantUrl) return '';
      const regex = /\/applicant\/([a-f\d]+)\/basicInfo/;
      const match = this.applicantUrl.match(regex);
      return match ? match[1] : '';
    },
  },
  methods: {
    ...mapActions('securitizeId', ['getInvestorVerificationLinkData', 'linkInvestorVerification', 'getSecuritizeIdInvestor']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    async getVerificationLinkData() {
      const { applicantId } = this;
      if (!applicantId) return;
      this.isLinkModalLoading = true;
      this.linkModalData = await this.getInvestorVerificationLinkData({
        investorId: this.getGeneralInfo.investorId,
        applicantId,
      });
      if (this.futureApplicantId !== this.getGeneralInfo.investorId && this.isValidObjectId(this.futureApplicantId)) {
        this.currentLinkedInvestorData = await this.getSecuritizeIdInvestor({ investorId: this.futureApplicantId })
            .catch(() => null);
      }
        this.isLinkModalLoading = false;
    },
    isValidObjectId(str) {
      const objectIdRegex = /^[0-9a-fA-F]{24}$/;
      return objectIdRegex.test(str);
    },
    clearModal() {
      this.applicantUrl = '';
      this.isLinkModalLoading = false;
      this.linkModalData = null;
      this.currentLinkedInvestorData = null;
      this.closeModal();
    },
    async onOk(e) {
      e.preventDefault();
      this.isLinkModalLoading = true;
      await this.linkInvestorVerification({
        investorId: this.getGeneralInfo.investorId,
        applicantId: this.applicantId,
      });
      this.isLinkModalLoading = false;
      this.closeModal();
    },
    openModal() {
      this.$refs['link-modal'].show();
    },
    closeModal() {
      this.$refs['link-modal'].hide();
    },
  },
};
</script>

<style lang="scss" scoped>

.font-85 {
  font-size: 85%;
}
</style>
