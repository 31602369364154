var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(_vm.isConfidential ? 'Confidential Documents' : 'Documents')+" ")]),_c('cp-edit-buttons',{attrs:{"edit-mode":_vm.editMode},on:{"changeMode":_vm.changeModeProxy,"update":_vm.saveChanges}},[_c('template',{slot:"leftToButtons"},[(!_vm.isConfidential)?_c('cp-button',{staticClass:"mr-2",attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.downloadingDocuments.all,"is-loading":_vm.downloadingDocuments.all},on:{"click":_vm.downloadAllDocument}},[_vm._v(" Download all documents ")]):_vm._e(),_c('cp-button',{staticClass:"mr-2",attrs:{"icon":"ios-add","variant":"primary"},on:{"click":_vm.showAddDocModal}},[_vm._v(" Add document ")])],1)],2)],1)])]),_c('cp-table',{ref:"cpTable",attrs:{"without-search":"","without-pagination":"","get-data-action":("securitizeId/" + (_vm.isConfidential ?
      'getSecuritizeIdInvestorConfidentialDocuments' :
      'getSecuritizeIdInvestorDocuments')),"url-params":_vm.urlParams,"fields":_vm.documentsFields,"empty-table-message":_vm.emptyTableMessage},scopedSlots:_vm._u([{key:"thumbnail",fn:function(ref){
      var rowData = ref.rowData;
return [_c('img',{ref:rowData.item.documentId,staticClass:"ui-w-40 d-block link",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getImageIcon(rowData.item),"alt":"Document preview"},on:{"click":function($event){return _vm.showPreviewDocModal(rowData.item)},"error":function($event){return _vm.showFallbackImage(rowData.item)}}})]}},{key:"docType",fn:function(ref){
      var rowData = ref.rowData;
return [(!_vm.editMode)?_c('span',[_vm._v(" "+_vm._s(_vm.getDocTypeName(rowData.item.docCategory, rowData.item.docType))+" ")]):_c('cp-select',{attrs:{"options":_vm.getDocumentTypesList()},model:{value:(_vm.model[rowData.index].docType),callback:function ($$v) {_vm.$set(_vm.model[rowData.index], "docType", $$v)},expression:"model[rowData.index].docType"}})]}},{key:"createDate",fn:function(ref){
      var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.createDate))+" ")]}},{key:"validUntil",fn:function(ref){
      var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(_vm._f("nullFilter")(rowData.item.validUntil)))+" ")]}},{key:"status",fn:function(ref){
      var rowData = ref.rowData;
return [(!_vm.editMode)?_c('b-badge',{class:_vm.getStatusColor(rowData.item)},[_vm._v(" "+_vm._s(rowData.item.verificationStatus)+" ")]):_c('cp-select',{attrs:{"options":_vm.statusOptions},model:{value:(_vm.model[rowData.index].verificationStatus),callback:function ($$v) {_vm.$set(_vm.model[rowData.index], "verificationStatus", $$v)},expression:"model[rowData.index].verificationStatus"}})]}},{key:"actions",fn:function(ref){
      var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.downloadingDocuments[rowData.item.documentId],"is-loading":_vm.downloadingDocuments[rowData.item.documentId]},on:{"click":function($event){return _vm.downloadDocument(rowData.item)}}},[_vm._v(" Download ")]),_c('cp-button',{attrs:{"icon":"ion ion-ios-trash","variant":"default ml-3"},on:{"click":function($event){return _vm.showDeleteModal(rowData.item)}}},[_vm._v(" Delete ")])],1)]}}])}),_c('add-document-modal',{ref:"addDocModal",attrs:{"categories":_vm.categories,"is-confidential":_vm.isConfidential},on:{"docIsAdded":_vm.refreshTable}}),_c('preview-document-modal',{ref:"previewDocModal",attrs:{"document-id":_vm.documentId,"investor-id":_vm.urlParams.investorId,"file-type":_vm.fileType,"is-confidential":_vm.isConfidential}}),_c('cp-confirm-modal',{ref:"cpConfirmModal",attrs:{"title":"Delete document","is-confidential":_vm.isConfidential},on:{"onOk":_vm.deleteDocument}},[_vm._v(" "+_vm._s(_vm.confirmModalText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }