<template>
  <div>
    <cp-button
      variant="outline-primary"
      icon="ios-finger-print"
      @click="openModal"
    >
      Sync to Sumsub
    </cp-button>
    <b-modal
      ref="link-modal"
      size="lg"
      :ok-disabled="isLoading"
      centered
      ok-title="Sync Now"
      cancel-title="Cancel"
      @ok="onOk"
    >
      <div
        slot="modal-header"
        style="width:100%; text-align: left"
      >
        <h5 class="modal-title d-flex justify-content-between">
          Sync From Securitize ID to Sumsub
        </h5>
      </div>
      <div>
        <div
          class="mt-4"
        >
          <div>
            You are about to synchronize the information from this {{ entityName }} on Securitize to its Sumsub applicant.
            This action will update and align the data between Securitize and Sumsub. Are you sure you want to proceed
          </div>
        </div>

        <div class="d-flex align-content-center justify-content-center">
          <Spinner
            v-if="isLoading"
            name="wave"
            class="mt-4"
            color="#04bec4"
            fade-in="quarter"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Spinner from 'vue-spinkit';
import ClickOutside from 'vue-click-outside';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'SyncToSumsub',
  components: {
    CpButton,
    Spinner,
  },
  directives: {
    ClickOutside,
  },
  props: {
    isLoading: {
      type: [Boolean],
      default: false,
    },
  },
  watch: {
    isLoading: {
      handler(val, oldVal) {
        if (val === false && oldVal === true) {
          this.closeModal();
        }
      },
      immediate: true,
    },
  },
    computed: {
      ...mapGetters('securitizeId', ['getGeneralInfo']),
      entityName() {
        return this.getGeneralInfo.entityDba || '-';
      },
  },
    methods: {
      ...mapActions('securitizeId', ['getSecuritizeIdInvestor']),
      ...mapMutations('global', ['CALL_ERROR_TOASTER']),
      openModal() {
        this.$refs['link-modal'].show();
      },
      closeModal() {
        this.$refs['link-modal'].hide();
      },
      onOk(e) {
        e.preventDefault();
        this.$emit('onAccept');
      },
    },
  };
</script>

<style lang="scss" scoped>

.font-85 {
  font-size: 85%;
}
</style>
