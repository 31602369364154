<template>
  <cp-general-modal
    ref="createModal"
    title="Add Key Party"
    title-icon="ion ion-ios-person-add"
    :ok-disabled="submitDisabled"
    :ok-text="okText"
    @shown="setInitial"
    @onOk="saveChanges"
    @formInvalid="handleFromInvalid"
    @onCancel="resetModalData"
  >
    <cp-select
      v-model="model.signerType"
      label="Type"
      name="signerType"
      :options="legalSignerOptionsList.signerType"
    />
    <div v-if="isIndividual">
      <div class="d-flex justify-content-between">
        <div class="w-80 mr-3">
          <cp-input
            v-model="individualNameModel.firstName"
            name="firstName"
            label="First Name"
            validate="required"
          />
        </div>
        <div class="w-80 mr-3">
          <cp-input
            v-model="individualNameModel.middleName"
            name="middleName"
            label="Middle Name"
          />
        </div>
        <div class="w-80">
          <cp-input
            v-model="individualNameModel.lastName"
            name="lastName"
            label="Last Name"
            validate="required"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="model.email"
            name="email"
            label="Email"
            validate="required|email"
          />
        </div>

        <div class="w-100">
          <cp-date-picker
            v-model="model.birthDate"
            name="birthDate"
            label="Birthdate"
            initial-view="year"
            validate="required"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-select
            v-model="address.countryCode"
            name="countryCode"
            placeholder
            label="Country"
            :options="countriesList"
            validate="required"
          />
        </div>
        <div class="w-100">
          <cp-select
            v-if="isUsa"
            v-model="address.state"
            placeholder
            name="state"
            label="State"
            :validate="isUsa ? 'required' : null"
            :options="stateList"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-select
            v-model="model.taxCountryCode"
            name="taxCountryCode"
            placeholder
            label="Tax Country"
            :options="countriesList"
          />
        </div>
        <div class="w-100">
          <cp-input
            v-model="model.taxId"
            name="taxId"
            label="Tax ID"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="address.city"
            name="city"
            label="City"
            validate="required"
          />
        </div>

        <div class="w-100">
          <cp-input
            v-model="address.street"
            name="street"
            label="Street Name"
            validate="required"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="address.houseNumber"
            name="houseNumber"
            label="Street Number"
            validate="required"
          />
        </div>
        <div class="w-100">
          <cp-input
            v-model="address.zip"
            name="zip"
            label="Postal Code"
            validate="required"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between custom-control custom-checkbox pl-4 mt-3">
        <input
          id="is-legal-signer"
          v-model="isLegalSigner"
          class="custom-control-input"
          type="checkbox"
        >
        <label
          for="is-legal-signer"
          class="form-label font-weight-semibold mb-1 custom-control-label"
        >
          This individual is a legal signer
        </label>
      </div>
    </div>
    <div v-if="!isIndividual">
      <cp-input
        v-model="model.legalName"
        name="legalName"
        label="Name"
        validate="required"
      />
      <cp-input
        v-model="model.email"
        name="email"
        label="Email"
        validate="required|email"
      />
      <cp-input
        v-model="model.businessName"
        name="businessName"
        label="DBA"
        validate="required"
      />
      <cp-select
        v-model="model.entityType"
        name="entityType"
        placeholder
        label="Type"
        :options="legalSignerOptionsList.entityTypesList"
        validate="required"
      />
      <cp-select
        v-model="model.taxCountryCode"
        name="taxCountryCode"
        placeholder
        label="Tax Country"
        :options="countriesList"
      />
      <cp-input
        v-model="model.taxId"
        name="taxId"
        placeholder
        label="Tax ID"
        validate="required"
      />
    </div>
    <div class="mt-2">
      <div class="form-label font-weight-semibold mb-1">
        Ultimate beneficial owner
      </div>
      <b-form-group>
        <b-form-radio
          v-for="({ text, value }, index) in legalSignerOptionsList.uboOptionsList"
          :key="index"
          v-model="model.uboOwning"
          :value="value"
          class="text-capitalize"
          inline
          plain
          @change="handleUBOChange"
        >
          <span>{{ text }}</span>
        </b-form-radio>
      </b-form-group>
    </div>
    <cp-select
      v-if="isIndividual"
      v-model="individualDocumentType"
      validate="required"
      class="mt-3"
      label="Identification Document Type"
      placeholder
      name="individualDocumentType2"
      value=""
      :options="documentTypes"
    />
    <cp-file-uploader
      v-if="isIndividual"
      id="create-legal-signer-uploader"
      class="mt-3"
      :document-error="documentError"
      :data-check="checkDockSize"
      :file-rules="checkDockSize"
      :max-file-size="maxDocSize"
      :use-secure="true"
      class-style-override="small-footprint"
      show-default-message
      dict-default-message="Upload Identification Document"
      @onChange="fileUploaded"
      @fileLoading="fileLoading"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';
import states from '~/utilities/us-states-list';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import {
  countriesList,
  legalSignerOptionsList,
} from '@/pages/securitize-id/components/options';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import documents from './documents/options';

export default {
  name: 'LegalSignerModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpFileUploader,
    CpDatePicker,
  },
  props: {
    countryCodesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      okText: 'Add',
      maxDocSize: 50000000, // max upload file size 50mb
      countriesList,
      legalSignerOptionsList,
      states,
      submitDisabled: false,
      investorId: this.$route.params.investorId,
      fileLoaded: false,
      documentError: false,
      isLegalSigner: false,
      individualNameModel: {
        firstName: '',
        lastName: '',
        middleName: '',
      },
      address: {
        street: '',
        houseNumber: '',
        city: '',
        countryCode: '',
        zip: '',
      },
      entityDocumentData: {
        side: 'front',
        docCategory: 'signer-entity',
      },
      individualDocumentData: {
        side: 'front',
        docCategory: 'signer-identification',
      },
      model: {},
      categories: documents.categories,
      entityDocumentType: null,
      individualDocumentType: {
        docCategory: 'identification',
        docType: 'passport',
      },
    };
  },
  computed: {
    stateList() {
      return Object.keys(this.states).map(key => ({
        value: key,
        text: this.states[key],
      }));
    },
    isIndividual() {
      return this.model.signerType === 'individual';
    },
    isUsa() {
      return this.address.countryCode === 'US';
    },
    documentTypes() {
      return this.isIndividual
        ? this.categories.documentTypeIndividual
        : this.categories.entityDocumentTypes;
    },
  },
  methods: {
    ...mapActions('securitizeId', ['createSecuritizeIdLegalSigner']),
    handleFromInvalid(isFormInvalid) {
      this.documentError = !!(isFormInvalid && !this.model.documents);
    },
    resetModalData() {
      this.model = {};
      this.individualNameModel = {};
      this.isLegalSigner = false;
      this.address = {};
      this.documentError = false;
      this.okText = 'Add Legal Signer';
    },
    show() {
      this.$refs.createModal.show();
    },
    checkDockSize({ size }) {
      return size > this.maxDocSize;
    },
    fileUploaded({ file, fileKey }) {
      if (!fileKey) {
        this.onUploadError(file);
        return;
      }
      this.model.documents = [{
        fileName: fileKey,
        fileType: file.type,
      }];
      this.handleFromInvalid(false);
      this.submitDisabled = false;
    },
    onUploadError(file) {
      let errorText = 'Upload Error';
      if (file && file.status === 'error') {
        if (file.xhr && file.xhr.responseText) {
          errorText = file.xhr.responseText;
        }
      }
      this.showErrorMessage(errorText);
    },
    showErrorMessage(error) {
      this.handleFromInvalid(true);
      this.$log.error('Legal signer upload file error:', error);
    },
    fileLoading() {
      this.submitDisabled = true;
    },
    setInitial() {
      this.model = {
        signerType: 'individual',
      };
    },
    saveChanges() {
      if (this.model.signerType === 'individual' && !this.model.documents) {
        this.handleFromInvalid(true);
        return;
      }

      if (this.model.signerType === 'individual') {
        this.model.documents[0] = { ...this.model.documents[0], ...this.individualDocumentData };
        this.model.documents[0].docType = this.individualDocumentType.docType;
        this.model.isLegalSigner = this.isLegalSigner;
      }

      this.submitDisabled = true;
      this.model.address = this.address;
      this.model.individualName = this.individualNameModel;
      this.okText = 'Saving...';
      this.createSecuritizeIdLegalSigner({
        investorId: this.investorId,
        legalSigner: this.model,
      })
        .then(() => {
          this.$emit('updateTable');
          this.$refs.createModal.hide();
        })
        .finally(() => {
          this.submitDisabled = false;
          this.resetModalData();
        });
    },
    handleUBOChange(val) {
      this.model.isBeneficialOwner = val !== 'none';
    },
  },
};
</script>
