var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.tokensWallets.subTitle.wallets'))+" ")]),_c('cp-button',{attrs:{"icon":"ios-add","variant":"primary"},on:{"click":_vm.showAddWalletModal}},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.tokensWallets.button.wallets.add'))+" ")])],1)])]),_c('securitize-id-add-investor-wallet',{ref:"addWalletModal",on:{"walletIsUpdated":_vm.refreshWalletsTable}}),_c('cp-table',{ref:"walletsTable",attrs:{"without-search":"","get-data-action":"securitizeId/getInvestorWallets","url-params":_vm.urlParams,"fields":_vm.tokensWallets.wallets},on:{"tableDataUpdated":_vm.setAddWalletDisabled},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.title)+" ")]}},{key:"address",fn:function(ref){
var rowData = ref.rowData;
return [_c('a',{attrs:{"href":rowData.item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(rowData.item.address)+" ")])]}},{key:"walletType",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.walletType)+" ")]}},{key:"blockchainType",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.blockchainType)+" ")]}},{key:"creationDate",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.formatCreationDate(rowData.item.creationDate))+" ")]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat ml-3"},on:{"click":function($event){return _vm.openDeleteModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-trash"})])],1)]}}])}),_c('cp-confirm-modal',{ref:"cpConfirmModal",attrs:{"title":_vm.$t('investorDetails.removeModal.title', [_vm.$t('investorDetails.tokensWallets.label.wallet')]),"item":_vm.deleteItem},on:{"onOk":_vm.walletRemove}},[_c('p',[_vm._v("Title: "+_vm._s(_vm.deleteItem.title))]),_c('p',[_vm._v("Address: "+_vm._s(_vm.deleteItem.address))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }