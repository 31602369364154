<template>
  <div>
    <h6>Requested updates:</h6>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column mr-3 w-50">
        <!-- Additional documentation is needed -->
        <div class="w-100">
          <b-card
            class="mb-1 bg-grey"
            no-body
          >
            <b-card-header>
              <a
                v-b-toggle.accordion-additional-doc
                class="d-flex justify-content-between text-dark"
                href="javascript:void(0)"
              >
                <div class="d-flex align-items-center">
                  <i
                    :class="{ 'hide-check': (isEdit && !hasSelectedAdditionalDocumentation) || !requestedAdditionalDocumentation.length }"
                    class="ion ion-ios-checkmark fa-2x mr-2"
                  />
                  Additional documentation is needed
                </div>
                <div
                  class="collapse-icon"
                />
              </a>
            </b-card-header>
            <b-collapse
              id="accordion-additional-doc"
              accordion="accordion-additional-doc"
            >
              <div class="mt-3 ml-3">
                <p
                  v-if="isEdit"
                  class="ml-4 font-weight-bold mb-3"
                >
                  Request Additional Documents
                </p>
                <div>
                  <div
                    v-for="doc in model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]"
                    :key="`doc-${doc.docType}`"
                  >
                    <div
                      v-if="doc.checked"
                      class="custom-control custom-checkbox additional-documents-required-box"
                    >
                      <button
                        v-if="isEdit"
                        type="button"
                        aria-label="Close"
                        class="close mr-2"
                        @click="() => removeRequiredDocument(doc)"
                      >
                        ×
                      </button>
                      <b-form-select
                        :value="model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][doc.docType].docType"
                        :options="
                          documentCategories.entityDocumentTypes
                            .map(item => ({
                              text: item.text,
                              value: item.value.docType,
                              disabled: model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][item.value.docType].checked,
                            }))
                        "
                        :disabled="!isEdit"
                        class="doc-type-select"
                        @change="(newDocType) => selectRequiredDocumentType(newDocType, doc.docType)"
                      />
                      <b-badge
                        :class="getStatusColor(doc.verificationStatus)"
                      >
                        {{ doc.verificationStatus }}
                      </b-badge>
                    </div>
                  </div>
                </div>
                <!-- Add new required document btn -->
                <cp-button
                  v-if="isEdit"
                  class="ml-4"
                  variant="primary"
                  @click="() => addNewRequiredDocument()"
                >
                  + Add Document
                </cp-button>
                <!-- Remove existing document Modal -->
                <cp-confirm-modal
                  :ref="`cpConfirmModal`"
                  title="Delete Document"
                  @onOk="() => deleteDocument()"
                  @onCancel="() => removeRequiredDocument(documentToDelete)"
                >
                  The investor already has
                  <span class="text-capitalize">{{ documentToDelete.docType && documentToDelete.docType.replaceAll('-', ' ') }}</span>
                  document, do you wish to delete the existing one and request it again?
                </cp-confirm-modal>
              </div>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!-- Legal signers are missing information -->
      <div class="d-flex w-50">
        <div class="w-100">
          <b-card
            class="mb-3 bg-grey"
            no-body
          >
            <b-card-header>
              <a
                v-b-toggle.accordion
                class="d-flex justify-content-between text-dark"
                href="javascript:void(0)"
              >
                <div class="d-flex align-items-center">
                  <i
                    :class="{ 'hide-check': (isEdit && !hasSelectedDocToLegalSigners) || !requestedDocToLegalSigners.length }"
                    class="ion ion-ios-checkmark fa-2x mr-2"
                  />
                  Uploaded key parties are missing information
                </div>
                <div
                  class="collapse-icon"
                />
              </a>
            </b-card-header>
            <b-collapse
              id="accordion"
              accordion="accordion"
            >
              <div
                v-if="!isEdit || getGeneralInfo.verificationStatus === 'updates-required'"
                class="d-flex flex-column ml-4"
              >
                <div
                  v-for="(requested, index) in requestedDocToLegalSigners"
                  :key="requested.legalSignerId"
                  class="mt-3 ml-3"
                >
                  <p class="font-weight-bold mb-2">
                    {{ index>=10?index + 1:`0${index + 1}` }}. {{ requested.signerName }}
                  </p>
                  <div
                    v-for="doc in requested.docs"
                    :key="doc"
                    class="d-flex align-items-center"
                  >
                    <i
                      class="ion ion-ios-checkmark fa-2x mr-2"
                      :class="{ 'hide-check': !requestedDocToLegalSigners.length }"
                    />
                    <span>{{ doc }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="isEdit && getGeneralInfo.verificationStatus !== 'updates-required'"
                class="d-flex flex-column ml-3"
              >
                <div
                  v-for="(signer, index) in legalSignersToShow"
                  :key="signer.signerId"
                  class="ml-4 mt-3"
                >
                  <p class=" font-weight-bold mb-2">
                    {{ index>=10?index + 1:`0${index + 1}` }}. {{ getSignerName(signer) }}
                  </p>
                  <div
                    v-for="doc in entityDocumentOptions(signer.signerType, signer.entityType, signer.documents)"
                    :key="`${doc.docType}-${signer.signerId}-${doc.documentId || ''}`"
                    class="custom-control custom-checkbox pl-4 mb-3"
                  >
                    <input
                      :id="`check-${signer.signerId}-${doc.docType}-${doc.documentId || ''}`"
                      v-model="model[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO][signer.signerId][doc.documentId || doc.docType]"
                      type="checkbox"
                      class="custom-control-input"
                      @change="() => onDocumentSelected(updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO, doc, signer)"
                    >
                    <label
                      :for="`check-${signer.signerId}-${doc.docType}-${doc.documentId || ''}`"
                      class="custom-control-label mr-3"
                    />
                    <span class="mr-3">{{ getDocumentName(doc.docType, signer.signerType, signer.entityType) }}
                      <span
                        v-if="!!doc.side"
                        class="text-capitalize"
                      >{{ " - " + doc.side }}</span>
                    </span>
                    <b-badge
                      :class="getStatusColor(doc.verificationStatus)"
                    >
                      {{ doc.verificationStatus }}
                    </b-badge>
                  </div>
                </div>
                <b-button
                  v-if="legalSigners.length > 2"
                  class="mt-3 ml-4"
                  variant="link"
                  @click="showMoreLegalSigners = !showMoreLegalSigners"
                >
                  {{ showMoreLegalSigners ? 'Show less' : 'Show more' }}
                </b-button>
              </div>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <!-- Comment for investor -->
    <div class="w-100">
      <b-card
        class="mb-1 bg-grey"
        no-body
      >
        <b-card-header>
          <a
            v-b-toggle.accordion-additional-comment
            class="d-flex justify-content-between text-dark"
            href="javascript:void(0)"
          >
            <div class="d-flex align-items-center">
              <i
                :class="{ 'hide-check': isEdit || !requestedAdditionalDocumentationText.length }"
                class="ion ion-ios-checkmark fa-2x mr-2"
              />
              Comment for investor:
            </div>
            <div
              class="collapse-icon"
            />
          </a>
        </b-card-header>
        <b-collapse
          id="accordion-additional-comment"
          accordion="accordion-additional-comment"
        >
          <div class="mt-3 ml-3">
            <cp-comments
              v-model.trim="model[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT][0]"
              :value="model[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT][0]"
              :comments-array="[commentUpdateName]"
              custom-height="100px"
              :is-editable="isEdit && getGeneralInfo.verificationStatus !== 'updates-required'"
            />
          </div>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import documents from './details/documents/options';
import { updatesRequiredTypes } from './options';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpComments from '~/components/common/standalone-components/cp-comments';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'CpSecuritizeIdInvestorDetailsKyc',
  components: {
    CpComments,
    CpButton,
    CpConfirmModal,
  },
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      model: {
        [updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]: {},
        [updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO]: {},
        [updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT]: {},
      },
      documentCategories: documents.categories,
      entityDocuments: [],
      updatesRequiredTypes,
      legalSigners: [],
      showMoreLegalSigners: false,
      documentToDelete: {},
    };
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    commentUpdateName() {
      const { updateName } = this.requestedAdditionalDocumentationText[0] || {};
      return updateName || '';
    },
    entityDocumentOptions() {
      return (investorType, entityType, docs) => {
        const result = [...docs];
        const documentsByCategory = investorType === 'individual'
          ? this.documentCategories.documentTypeIndividual
          : this.documentCategories.documentsTypeEntity[entityType];
        return documentsByCategory.reduce((accum, doc) => {
          const found = docs.find(({ docType }) => docType === doc.value.docType);
          return (found || (doc.value.docType === 'other' && doc.value.docCategory === 'other'))
            ? accum : [...accum, {
              docType: doc.value.docType,
              verificationStatus: 'Not uploaded',
            }];
        }, result);
      };
    },
    hasRequestMoreLegalSigners() {
      return this.getGeneralInfo.requestedUpdates
        .some(({ updateType }) => updateType.replaceAll('-', '_')
          .toUpperCase() === updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS);
    },
    requestedAdditionalDocumentationText() {
      return this.getGeneralInfo.requestedUpdates
          .filter(({ updateType }) => updateType.replaceAll('-', '_')
              .toUpperCase() === updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT);
    },
    requestedAdditionalDocumentation() {
      return this.getGeneralInfo.requestedUpdates
        .filter(({ updateType }) => updateType.replaceAll('-', '_')
          .toUpperCase() === updatesRequiredTypes.ADDITIONAL_DOCUMENTATION);
    },
    requestedComment() {
      return this.getGeneralInfo.requestedUpdates.filter(({ updateType }) => updateType.replaceAll('-', '_')
          .toUpperCase() === updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT);
    },
    requestedDocToLegalSigners() {
      return this.getGeneralInfo.requestedUpdates
        .filter(({ updateType }) => updateType.replaceAll('-', '_')
          .toUpperCase() === updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO)
        .reduce((accum, item) => {
          const index = accum.findIndex(signer => signer.signerId === item.legalSignerId);
          if (index !== -1) {
            const [found] = accum.splice(index, 1);
            return [
              ...accum, {
                ...found,
                docs: [...found.docs, item.updateName],
              },
            ];
          }
          return [
            ...accum, {
              signerName: item.legalSignerName,
              signerId: item.legalSignerId,
              docs: [item.updateName],
            }];
        }, []);
    },
    hasSelectedAdditionalDocumentation() {
      return Object.keys(this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION])
        .reduce((accum, key) => {
          if (this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][key]) {
            return [...accum, this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][key]];
          }
          return accum;
        }, [])
        .some(item => item);
    },
    hasSelectedDocToLegalSigners() {
      return Object.keys(this.model[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO])
        .reduce((accum, key) => {
          const items = Object.keys(this.model[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO][key])
            .reduce((acc, doc) => [...acc, this.model[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO][key][doc]], []);
          return [...accum, ...items];
        }, [])
        .some(item => item);
    },
    legalSignersToShow() {
      if (this.showMoreLegalSigners) return this.legalSigners;
      return this.legalSigners.slice(0, 2);
    },
    commentsSection() {
      return this.model[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT];
    },
  },
  watch: {
    commentsSection: {
      deep: true,
      handler() {
        this.onComment();
      },
    },
    isEdit: {
      immediate: true,
      handler() {
        this.model = {
          [updatesRequiredTypes.ADDITIONAL_DOCUMENTATION]: {},
          [updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO]: {},
          [updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS]: false,
          [updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT]: {},
        };

        this.model[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO] = this.legalSigners
          .reduce((result, item) => ({ ...result, ...{ [item.signerId]: {} } }), {});

        this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION] = {
          ...this.documentCategories.entityDocumentTypes
            .reduce((acc, doc) => ({
                  ...acc,
                  [doc.value.docType]: {
                    docType: doc.value.docType,
                    checked: false,
                    verificationStatus: 'requested',
                  },
            }), {}),
          ...this.getGeneralInfo.requestedUpdates
            .filter(doc => doc.updateType === updatesRequiredTypes.ADDITIONAL_DOCUMENTATION.toLowerCase().replaceAll('_', '-'))
            .reduce((acc, doc) => ({
                    ...acc,
                    [doc.updateDocType]: {
                      docType: doc.updateDocType,
                      checked: true,
                      verificationStatus: 'requested',
                    },
              }), {}),
        };

        this.model[updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS] = !!this.getGeneralInfo.requestedUpdates
            .find(doc => doc.updateType === updatesRequiredTypes.ADDITIONAL_LEGAL_SIGNERS.toLowerCase().replaceAll('_', '-'));

        if (this.isEdit) {
          this.getGeneralInfo.requestedUpdates
            .filter(doc => doc.updateType === updatesRequiredTypes.ADDITIONAL_DOCUMENTATION.toLowerCase().replaceAll('_', '-'))
            .map(doc => this.onDocumentSelected(updatesRequiredTypes.ADDITIONAL_DOCUMENTATION, { docType: doc.updateDocType, checked: true, verificationStatus: 'requested' }));
        }
      },
    },
  },
  created() {
    const params = { params: { investorId: this.getGeneralInfo.investorId } };
    this.getSecuritizeIdInvestorDocuments(params)
      .then(({ data: { data } }) => {
        this.entityDocuments = data;
        this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION] = {
          ...this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION],
          ...data.reduce((acc, doc) => ({
              ...acc,
              [doc.docType]: {
                docType: doc.docType,
                checked: false,
                documentId: doc.documentId,
                verificationStatus: doc.verificationStatus,
              },
            }), {}),
          ...this.getGeneralInfo.requestedUpdates
            .filter(doc => doc.updateType === updatesRequiredTypes.ADDITIONAL_DOCUMENTATION.toLowerCase().replaceAll('_', '-'))
            .reduce((acc, doc) => ({
                    ...acc,
                    [doc.updateDocType]: {
                      docType: doc.updateDocType,
                      checked: true,
                      verificationStatus: 'requested',
                    },
              }), {}),
        };
      });
    this.getSecuritizeIdLegalSignersList(params)
      .then(({ data: { data } }) => {
        this.legalSigners = data;
        this.model[updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO] = this.legalSigners
          .reduce((result, item) => ({ ...result, ...{ [item.signerId]: {} } }), {});
      });
  },
  methods: {
    ...mapActions('securitizeId', [
      'getSecuritizeIdInvestorDocuments',
      'getSecuritizeIdLegalSignersList',
      'deleteSecuritizeIdInvestorDocumentById',
    ]),
    getDocumentName(docType, investorType, entityType) {
      const documentsByType = investorType === 'individual'
        ? this.documentCategories.documentTypeIndividual
        : this.documentCategories.documentsTypeEntity[entityType];
      const found = documentsByType.find(({ value }) => value.docType === docType);
      if (!found) return docType;
      return found.text;
    },
    getStatusColor(verificationStatus) {
      let statusColor = 'badge-outline-dark';
      switch (verificationStatus) {
        case 'pending':
          statusColor = 'badge-outline-primary';
          break;
        case 'not-verified':
          statusColor = 'badge-outline-danger';
          break;
        case 'manual-review':
          statusColor = 'badge-outline-dark';
          break;
        case 'verified':
          statusColor = 'badge-outline-success';
          break;
        default:
      }
      return `${statusColor}`;
    },
    onComment() {
      const data = Object.values(this.model[updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT]).filter(Boolean);
      this.$emit('updateRequiredChange', {
        updateType: updatesRequiredTypes.COMMENT_TO_INVESTOR_COMMENT,
        data,
      });
    },
    selectRequiredDocumentType(newDocType, oldDocType) {
      const existingDocument = this.entityDocuments.find(doc => doc.docType === newDocType);
      if (existingDocument) {
        this.documentToDelete = { ...existingDocument };
        this.$refs.cpConfirmModal.show();
      }

      this.$set(
        this.model,
        updatesRequiredTypes.ADDITIONAL_DOCUMENTATION,
        {
          ...this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION],
          [newDocType]: { docType: newDocType, checked: true, verificationStatus: 'requested' },
          [oldDocType]: { docType: oldDocType, checked: false, verificationStatus: 'requested' },
        },
      );

      this.onDocumentSelected(updatesRequiredTypes.ADDITIONAL_DOCUMENTATION, this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][oldDocType]);
      this.onDocumentSelected(updatesRequiredTypes.ADDITIONAL_DOCUMENTATION, this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][newDocType]);
    },
    removeRequiredDocument(doc) {
      this.$set(
        this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][doc.docType],
        'checked', false,
      );
      this.onDocumentSelected(updatesRequiredTypes.ADDITIONAL_DOCUMENTATION, this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION][doc.docType]);
    },
    deleteDocument() {
      this.deleteSecuritizeIdInvestorDocumentById({
        investorId: this.getGeneralInfo.investorId,
        documentId: this.documentToDelete.documentId,
      }).then(() => {
        this.$emit('documentsUpdated');
        this.entityDocuments = this.entityDocuments.filter(doc => doc.docType !== this.documentToDelete.docType);
        this.removeRequiredDocument(this.documentToDelete.docType);
      });
    },
    onDocumentSelected(updateType, doc = {}, signer = {}) {
      const { docType, documentId } = doc;
      const { signerId, entityType, signerType } = signer;

      let checked = false;
      let newDocType;
      if (updateType === updatesRequiredTypes.ADDITIONAL_DOCUMENTATION) {
        checked = this.model[updateType][docType] && this.model[updateType][docType].checked;

        newDocType = this.model[updateType][docType].docType || doc.docType;
      } else if (updateType === updatesRequiredTypes.LEGAL_SIGNERS_MISSING_INFO) {
        checked = this.model[updateType][signerId][documentId || docType];
      } else {
        checked = this.model[updateType];
      }

      this.$emit('updateRequiredChange', {
        updateType,
        data: {
          docId: documentId,
          docType: newDocType || docType,
          signerId,
          signerName: signerId ? this.getSignerName(signer) : null,
          entityType,
          investorType: signerType,
          checked,
        },
      });
    },
    getSignerName({ signerType, individualName, legalName }) {
      return signerType === 'entity' ? legalName : [individualName.firstName, individualName.lastName].join(' ');
    },
    addNewRequiredDocument() {
      this.$set(
        this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION].other,
        'checked', true,
      );
      this.onDocumentSelected(updatesRequiredTypes.ADDITIONAL_DOCUMENTATION, this.model[updatesRequiredTypes.ADDITIONAL_DOCUMENTATION].other);
    },
  },
};
</script>

<style lang="scss" scoped>
  .bg-grey {
    background-color: rgba(10, 23, 39, 0.025)
  }

  .hide-check {
    color: rgba(10, 23, 39, 0.025)
  }

  .additional-documents-required-box {
    display: flex;
    align-items: center;
    padding: 0 0 1rem 1.5rem;

    .checkbox-box {
      position: relative;
      margin-right: 2rem;
    }

    .doc-type-select {
      max-width: 60%;
      margin-right: 1rem;
    }
  }
</style>
