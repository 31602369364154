<template>
  <cp-general-modal
    ref="addWallet"
    :title="$t('investorDetails.addInvestorWalletModal.title')"
    :ok-disabled="disabled"
    @onOk="addWallet"
  >
    <cp-input
      class="mb-2"
      name="name"
      :label="$t('investorDetails.addInvestorWalletModal.input.name')"
      validate="required"
    />
    <label class="form-label">
      {{ $t('investorDetails.addInvestorWalletModal.blockchainType') }}
    </label>
    <b-select
      v-model="blockchainType"
      class="mb-2"
      :options="['ETH', 'algorand']"
    />
    <cp-input
      name="address"
      :label="$t('investorDetails.addInvestorWalletModal.input.address')"
      :validate="`required|wallet_address:${blockchainType}`"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';

import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'SecuritizeIdAddInvestorWallet',
  components: {
    CpInput,
    CpGeneralModal,
  },
  data() {
    return {
      disabled: false,
      blockchainType: 'ETH',
    };
  },
  methods: {
    ...mapActions('securitizeId', ['addInvestorWallet']),
    show() {
      this.$refs.addWallet.show();
    },
    addWallet(body) {
      this.disabled = true;
      const { investorId } = this.$route.params;
      const { name: title, ...wallet } = body;
      this.addInvestorWallet({
        investorId,
        wallet: {
          ...wallet,
          title,
          blockchainType: this.blockchainType,
        },
      }).then(() => {
        this.$emit('walletIsUpdated');
        this.$refs.addWallet.hide();
      }).finally(() => {
        this.disabled = false;
      });
    },
  },
};
</script>
