<template>
  <div>
    <div class="card mb-5 authorized-accounts">
      <cp-card-head
        card-name="Authorized Users"
        without-edit
        create-elem
        button-title="Add"
        button-icon="ios-add"
        :is-disabled="isAddAccountDisabled"
        @onCreateElem="$emit('addAuthorizedAccountModel', authorizedInvestors)"
      />
      <cp-table
        ref="cpTable"
        empty-table-message="No authorized account found"
        without-pagination
        without-search
        get-data-action="securitizeId/getSecuritizeIdAuthorizedAccount"
        rows-clickable
        :url-params="urlParams"
        :fields="fields"
        @rowClicked="handleRowClick"
      >
        <template
          slot="displayName"
          slot-scope="{ rowData: {item, index}}"
        >
          <div
            :id="`${index}-display-name`"
            :class="item.investorId ? '' : 'disabledRow'"
          >
            {{ item.displayName || '-' }}

            <b-tooltip
              v-if="!item.investorId"
              :id="`${index}-display-name-tooltip`"
              :ref="`${index}-display-name-tooltip`"
              triggers="hover"
              :target="`${index}-display-name`"
              placement="bottom"
              custom-class="custom-tooltip"
            >
              <p>This user does not have a Securitize ID yet</p>
            </b-tooltip>
          </div>
        </template>
        <template
          slot="email"
          slot-scope="{ rowData: {item, index}}"
        >
          <div
            :id="`${index}-email`"
            :class="item.investorId ? '' : 'disabledRow'"
          >
            {{ item.email }}
            <b-badge
              v-if="item.status === 'pending'"
              class="ml-2"
              variant="outline-warning"
            >
              {{ item.status }}
            </b-badge>
            <b-tooltip
              v-if="!item.investorId"
              :id="`${index}-email-tooltip`"
              :ref="`${index}-email-tooltip`"
              triggers="hover"
              :target="`${index}-email`"
              placement="bottom"
              custom-class="custom-tooltip"
            >
              <p>This user does not have a Securitize ID yet</p>
            </b-tooltip>
          </div>
        </template>
        <template
          slot="role"
          slot-scope="{ rowData: {item, index}}"
        >
          <div
            :id="`${index}-role`"
            :class="`text-capitalize font-12 ${item.investorId ? '' : 'disabledRow'}`"
          >
            {{ accountRole(item) }}
            <b-tooltip
              v-if="!item.investorId"
              :id="`${index}-role-tooltip`"
              :ref="`${index}-role-tooltip`"
              triggers="hover"
              :target="`${index}-role`"
              placement="bottom"
              custom-class="custom-tooltip"
            >
              <p>This user does not have a Securitize ID yet</p>
            </b-tooltip>
          </div>
        </template>
        <template
          slot="actions"
          slot-scope="{ rowData: {item}}"
        >
          <div
            v-if="item.status !== 'owner'"
            class="d-flex"
          >
            <b-dropdown
              right
              no-caret
              style="width:20px;height:20px;"
              variant="btn btn-default p-1 btn-xs icon-btn d-flex justify-content-center align-items-center cp-button-with-loader"
            >
              <template slot="button-content">
                <i class="ion ion-ios-more" />
              </template>
              <div class="p-1 pl-2 pr-2">
                <cp-button
                  style="justify-content: start !important;"
                  variant="btn btn-default w-100 p-1 pl-3 btn-xs md-btn-flat"
                  @click="$emit('onRemoveAuthorizedAccount', item.email)"
                >
                  <i class="ion ion-ios-trash mr-2" />
                  Remove account
                </cp-button>
                <cp-button
                  v-if="item.status !== 'approved'"
                  style="justify-content: start !important;"
                  variant="btn btn-outline-primary w-100 mt-2 p-1 pl-3 btn-xs md-btn-flat"
                  @click="sendAuthorizedAccountInvite(item.email, item.role)"
                >
                  <i class="ion ion-md-send mr-1" />
                  Resend invite
                </cp-button>
                <cp-button
                  v-if="!isMissingOwner"
                  :disabled="isSubmitting"
                  style="justify-content: start !important;width: 12rem !important;"
                  variant="btn btn-default w-100 mt-2 p-1 pl-3 btn-xs md-btn-flat"
                  @click="changeAuthorizedAccoutnRole(item)"
                >
                  <i class="ion mr-1" />
                  {{ calculateRole(item.role) === 'admin' ? 'Change to viewer' : 'Change to Authorized Signer' }}
                </cp-button>
                <span
                  v-b-tooltip.hover.left
                  :title="'Transfer of ownership requires the identity of this user to be verified first'"
                  :disabled="item.verificationStatus === 'verified'"
                >
                  <cp-button
                    v-if="isAuthorizedSigner(item.role) && item.status === 'approved'"
                    :disabled="isSubmitting || item.verificationStatus !== 'verified'"
                    style="justify-content: start !important;"
                    variant="btn btn-default w-100 mt-2 p-1 pl-3 btn-xs md-btn-flat"
                    @click="$emit('onTransferAccountOwnership', { newOwnerUserId: item.userId, newOwnerName: item.displayName })"
                  >
                    Transfer Ownership
                  </cp-button>
                </span>
              </div>
            </b-dropdown>
          </div>
        </template>
      </cp-table>
    </div>
  </div>
</template>

<script>

import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import fields from './fields';
import CpCardHead from './card-head';
import routeNames from '~/utilities/routes';

export default {
  name: 'SecuritizeIdAuthorizedAccountsTable',
  components: {
    CpTable,
    CpCardHead,
    CpButton,
  },
  props: {
    isUpdateTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlParams: { investorId: this.$route.params.investorId },
      fields,
      tableData: [],
      isSubmitting: false,
      isLoading: true,
    };
  },
  computed: {
    authorizedInvestors() {
      return this.$refs.cpTable.items || [];
    },
    isMissingOwner() {
      return !this.authorizedInvestors.find(authAccount => authAccount?.status === 'owner');
    },
    isAddAccountDisabled() {
      return this.isLoading || (this.isMissingOwner && !!this.authorizedInvestors?.length);
    },
  },
  watch: {
    isUpdateTable(val) {
      if (val) {
        this.isSubmitting = !val;
        this.$refs.cpTable._updateTableData();
      }
    },
  },
  mounted() {
    this.$watch(
      () => this.$refs.cpTable.items,
      (val) => {
        this.isLoading = !val;
      },
    );
  },
  methods: {
    sendAuthorizedAccountInvite(email, role) {
      this.$refs.cpTable.$el.click();
      this.$emit('onResendAuthorizedAccountInvite', email, role);
    },
    changeAuthorizedAccoutnRole({ email, role, userId }) {
      const newRole = this.calculateRole(role) === 'admin' ? 'viewer' : 'admin';

      this.isSubmitting = true;
      this.$refs.cpTable.$el.click();
      this.$emit('onChangeAuthorizedAccoutnRole', { investorId: this.$route.params.investorId, email, inviteeUserId: userId, newRole });
    },
    calculateRole(role) {
      return ['admin', 'restricted-signer'].includes(role) ? 'admin' : role;
    },
    accountRole(invitation) {
      if (invitation.status === 'owner') return invitation.status;

      // if there is no owners, and only 1 pending admin, it's invited owner
      const isPendingOwner = this.isMissingOwner
        && this.authorizedInvestors.length === 1
        && invitation.investorId === null
        && invitation.role === 'admin'
        && invitation.status === 'pending';
      return isPendingOwner
        ? 'owner'
        : invitation.role === 'admin'
          ? 'Authorized Signer'
          : invitation.role;
    },
    isAuthorizedSigner(invitation) {
      return ['admin', 'restricted-signer'].includes(invitation);
    },
    handleRowClick({ data: { investorId } }) {
      if (!investorId) return;
      const route = this.$router.resolve(routeNames.securitizeIdInvestor(investorId));
      window.open(route.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
  .authorized-accounts {
    .card-header {
      border-bottom: none;
    }
  }
  .disabledRow {
    opacity: 0.6;
  }
</style>
