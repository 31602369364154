<template>
  <div
    id="kyc-table"
    class="w-100"
  >
    <cp-table
      ref="cpTable"
      :no-local-sorting="false"
      default-sort-by="date"
      get-data-action="securitizeId/getSecuritizeIdInvestorKycHistory"
      :fields="kycLogTableFields"
      table-title="KYC Status Log"
      :without-search="true"
      :without-pagination="true"
      :url-params="{ investorId }"
      @tableDataUpdated="onTableUpdated"
    >
      <template
        slot="date"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.date | dateFilter }}
        </span>
      </template>
      <template
        slot="kycStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :class="getStatusClass(rowData.item)"
        >
          {{ calculateStatus(rowData.item) }}
        </b-badge>
      </template>
      <template
        slot="operator"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.operator || rowData.item.provider }}
        </span>
      </template>
    </cp-table>
  </div>
</template>

<script>
import CpTable from '@/components/shared/cp-table/index';
import CpTimeConverter from '@/mixins/time-converter';
import { kycLogTableFields, kycStatuses } from '@/pages/securitize-id/components/options';

export default {
  name: 'CpSecuritizeIdKycLogTable',
  components: {
    CpTable,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    investorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      kycStatuses,
      kycLogTableFields,
      totalTableData: [],
    };
  },
  methods: {
    getStatusClass(item) {
      return this.kycStatuses[item.status];
    },
    calculateStatus(item) {
      const blocked = item.status === 'rejected'
          && item.subStatus === 'blocked'
          && 'rejected[blocked]';
      const documentsExpired = item.status === 'verified'
          && item.subStatus === 'documents-expired'
          && 'verified[documents-expired]';
      const expired = item.status === 'verified'
          && item.subStatus === 'expired'
          && 'verified[expired]';
      const enhanceVerified = item.status === 'verified'
          && item.level === 'enhanced'
          && 'enhance-verified';

      return blocked || documentsExpired || expired || enhanceVerified || item.status;
    },
    onTableUpdated({ items }) {
      if (items) {
        this.$emit('historyUpdated', items);
      }
    },
  },
};
</script>

<style lang="scss">
  #kyc-table {
    .table-responsive {
      max-height: 175px;
      overflow: auto;
      min-height: auto;
    }
  }
</style>
