<template>
  <b-modal
    ref="uploadDocument"
    title="Add Document"
    :ok-disabled="createProcess"
    :ok-title="okTitle"

    @show="clearModal"
    @ok="sendDocument"
  >
    <cp-file-uploader
      id="investor-document-uploader"
      :data-check="checkDockSize"
      :file-rules="checkDockSize"
      :max-file-size="maxDocSize"
      :use-secure="true"
      upload-end-point="upload"
      @onChange="onDocumentUploaded"
      @fileLoading="fileLoading"
    />
    <hr>

    <cp-select
      v-model="document"
      label="Document Type"
      placeholder
      :options="documentTypes"
    />

    <cp-select
      v-if="isDriverLicense"
      v-model="side"
      label="Document Face"
      placeholder
      :options="categories.face"
    />
    <span v-if="isEntityTypeMissing">{{ $t('securitizeId.fileUpload.entity-type-required') }}</span>
  </b-modal>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'AddDocumentModal',
  components: {
    CpSelect,
    CpFileUploader,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    isConfidential: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      newFile: {},
      fileKey: '',
      maxDocSize: 50000000, // max upload file size 50mb
      document: {},
      okTitle: 'Add Document',
      side: 'front',
      createProcess: false,
      countryDependedEntities: [
        'irrevocable-trust',
        'limited-partnership/general-partnership',
        'llc',
        'corporation',
      ],
    };
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    isDriverLicense() {
      return this.document && this.document.docType === 'driving-licence';
    },
    documentTypes() {
      const { investorType } = this.getGeneralInfo;
      if (investorType === 'entity' && this.isConfidential) {
        return this.categories.entityConfidentialDocumentTypes;
      }
      if (investorType === 'entity') {
        return this.categories.entityDocumentTypes;
      }
      if (investorType === 'individual' && this.isConfidential) {
        return this.categories.individualConfidentialDocumentTypes;
      }
      return this.categories.documentTypeIndividual;
    },
    isEntityTypeMissing() {
      const { entityType } = this.getGeneralInfo;
      if (this.isEntity && this.isConfidential) {
        return false;
      }
      return this.isEntity && !this.categories.documentsTypeEntity[entityType];
    },
    isEntity() {
      const { investorType } = this.getGeneralInfo;
      return investorType === 'entity';
    },
    documentType() {
      return this.document.docType;
    },
  },
  methods: {
    ...mapActions('securitizeId', ['addSecuritizeIdInvestorDocument', 'addSecuritizeIdInvestorConfidentialDocument']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    show() {
      this.$refs.uploadDocument.show();
    },
    checkDockSize({ size }) {
      return size > this.maxDocSize;
    },
    fileLoading() {
      this.createProcess = true;
    },
    onDocumentUploaded({ file, fileKey }) {
      if (!fileKey) this.onUploadError(file);
      this.newFile = file;
      this.fileKey = fileKey;
      this.createProcess = false;
    },
    onUploadError(file) {
      let errorText = 'Upload Error';
      if (file && file.status === 'error') {
        if (file.xhr && file.xhr.responseText) {
          errorText = file.xhr.responseText;
        }
      }
      this.showErrorMessage(errorText);
    },
    showErrorMessage(error) {
      this.CALL_ERROR_TOASTER(error);
      this.$log.error('Securitize id add document upload file error:', error);
    },
    clearModal() {
      this.newFile = {};
      this.document = {};
      this.side = 'front';
    },
    sendDocument(env) {
      env.preventDefault();
      if (!this.newFile) {
        this.CALL_ERROR_TOASTER('Please upload the document');
        return;
      } if (!this.newFile.accepted) {
        this.CALL_ERROR_TOASTER('File must be between 20KB and 50MB');
        return;
      }
      if (!this.documentType) {
        this.CALL_ERROR_TOASTER('Please select a document type');
        return;
      }

      if (this.createProcess) {
        return;
      }
      this.createProcess = true;
      this.okTitle = 'Saving...';

      const save = this.isConfidential ? this.addSecuritizeIdInvestorConfidentialDocument : this.addSecuritizeIdInvestorDocument;
      const payload = {
        investorId: this.getGeneralInfo.investorId,
        fileKey: this.fileKey,
        fileType: this.newFile.type,
        ...this.document,
      };

      if (!this.isConfidential) {
        payload.side = this.side;
      }

      save(payload).then(() => {
        this.$refs.uploadDocument.hide();
        this.$emit('docIsAdded');
      }).finally(() => {
        this.createProcess = false;
        this.okTitle = 'Add Document';
      });
    },
  },
};
</script>
