export default [
  {
    key: 'signerType',
    label: 'Type',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'isLegalSigner',
    label: 'Legal Signer',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'isBeneficialOwner',
    label: 'UBO',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'name',
    label: 'Name',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'taxId',
    label: 'ID',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'actions',
    label: '',
    tdClass: 'align-middle p-3 pl-4',
  },
];
