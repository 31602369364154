
export default [
  {
    key: 'displayName',
    label: 'Name',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'email',
    label: 'Email',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'role',
    label: 'Role',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
  {
    key: 'actions',
    label: '',
    tdClass: 'align-middle p-3 pl-4',
    thClass: 'align-middle p-3 pl-4',
  },
];
