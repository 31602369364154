export const statusStyle = {
  yes: 'outline-success',
  ready: 'outline-success',
  success: 'outline-success',
  distributed: 'outline-success',
  pending: 'outline-secondary',
  syncing: 'outline-info',
  processing: 'outline-info',
  failure: 'outline-danger',
  no: 'outline-danger',
};

export const tokensWallets = {
  wallets: [
    { key: 'title', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.name' },
    { key: 'address', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.address' },
    { key: 'walletType', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.walletType' },
    { key: 'blockchainType', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.blockchainType' },
    { key: 'creationDate', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.creationDate' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ],
  walletStatuses: [
    { value: 'syncing', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.syncing' },
    { value: 'pending', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.pending' },
    { value: 'ready', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.ready' },
  ],
};
