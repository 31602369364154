<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="mb-0 text-primary">
            {{ $t('investorDetails.tokensWallets.subTitle.wallets') }}
          </h4>
          <cp-button
            icon="ios-add"
            variant="primary"
            @click="showAddWalletModal"
          >
            {{ $t('investorDetails.tokensWallets.button.wallets.add') }}
          </cp-button>
        </div>
      </div>
    </div>

    <securitize-id-add-investor-wallet
      ref="addWalletModal"
      @walletIsUpdated="refreshWalletsTable"
    />

    <!--wallets table-->
    <cp-table
      ref="walletsTable"
      without-search
      get-data-action="securitizeId/getInvestorWallets"
      :url-params="urlParams"
      :fields="tokensWallets.wallets"
      @tableDataUpdated="setAddWalletDisabled"
    >
      <template
        slot="title"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.title }}
      </template>

      <template
        slot="address"
        slot-scope="{ rowData }"
      >
        <a
          :href="rowData.item.link"
          target="_blank"
        >
          {{ rowData.item.address }}
        </a>
      </template>

      <template
        slot="walletType"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.walletType }}
      </template>

      <template
        slot="blockchainType"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.blockchainType }}
      </template>

      <template
        slot="creationDate"
        slot-scope="{ rowData }"
      >
        {{ formatCreationDate(rowData.item.creationDate) }}
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="openDeleteModal(rowData.item)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </div>
      </template>
    </cp-table>

    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('investorDetails.removeModal.title', [$t('investorDetails.tokensWallets.label.wallet')])"
      :item="deleteItem"
      @onOk="walletRemove"
    >
      <p>Title: {{ deleteItem.title }}</p>
      <p>Address: {{ deleteItem.address }}</p>
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatDate } from '~/utilities/date-utils';
import CpTable from '@/components/shared/cp-table/index';
import CpButton from '~/components/common/standalone-components/cp-button';
import { statusStyle, tokensWallets } from './options';
import SecuritizeIdAddInvestorWallet
  from '@/pages/securitize-id/components/details/investors/modals/add-investor-wallet-modal';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';

export default {
  name: 'SecuritizeIdWalletsTable',
  components: {
    SecuritizeIdAddInvestorWallet,
    CpTable,
    CpButton,
    CpConfirmModal,
  },
  data() {
    return {
      investorId: this.$route.params.investorId,
      statusStyle,
      tokensWallets,
      deleteItem: {},
      addWalletDisabled: false,
    };
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    ...mapGetters('currentOperator', ['operatorData', 'authorizationLevel']),
    ...mapState('securitizeId', ['securitizeIdInvestor']),
    urlParams() {
      return { investorId: this.getGeneralInfo.investorId };
    },
  },
  methods: {
    ...mapActions('securitizeId', ['getInvestorWallets', 'deleteInvestorWallet']),
    showAddWalletModal() {
      this.$refs.addWalletModal.show();
    },
    refreshWalletsTable() {
      this.$refs.walletsTable.updateTableData();
    },
    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    walletRemove({ _id }) {
      this.deleteInvestorWallet({
        investorId: this.investorId,
        walletId: _id,
      }).then(this.refreshWalletsTable);
    },
    formatCreationDate(date) {
      return formatDate(date);
    },
    setAddWalletDisabled({ items }) {
      this.addWalletDisabled = items && items.length >= 10;
    },
  },
};
</script>
