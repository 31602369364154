<template>
  <div>
    <div class="card mb-5 fundraise-round">
      <cp-card-head
        card-name="Key Parties"
        without-edit
        create-elem
        button-title="Add"
        button-icon="ios-add"
        @onCreateElem="$emit('addLegalSignerModel')"
      />
      <cp-table
        ref="cpTable"
        without-pagination
        without-search
        get-data-action="securitizeId/getSecuritizeIdLegalSignersList"
        :url-params="urlParams"
        :fields="fields"
      >
        <template
          slot="isLegalSigner"
          slot-scope="{ rowData: {item}}"
        >
          <span> {{ getIsLegalSigner(item.isLegalSigner) }} </span>
        </template>
        <template
          slot="isBeneficialOwner"
          slot-scope="{ rowData: {item}}"
        >
          <span> {{ getUboOwning(item.uboOwning) }} </span>
        </template>
        <template
          slot="name"
          slot-scope="{ rowData: {item}}"
        >
          <span v-if="item.signerType === 'entity'">{{ item.legalName }}</span>
          <span v-if="item.signerType === 'individual'">{{ item.individualName.firstName }} {{ item.individualName.lastName }}</span>
        </template>
        <template
          slot="taxId"
          slot-scope="{ rowData: {item}}"
        >
          <span>{{ item.taxId | nullToMinus }}</span>
        </template>
        <template
          slot="actions"
          slot-scope="{ rowData }"
        >
          <div class="d-flex">
            <cp-button
              variant="default btn-xs icon-btn md-btn-flat"
              @click="$emit('onViewElem', rowData.item)"
            >
              <i class="ion ion-ios-eye" />
            </cp-button>
            <cp-button
              variant="default btn-xs icon-btn md-btn-flat ml-3"
              @click="verifyDeleteInvestor(rowData.item)"
            >
              <i class="ion ion-ios-trash" />
            </cp-button>
          </div>

          <div class="d-flex" />
        </template>
      </cp-table>
      <cp-confirm-modal
        ref="cpConfirmDeleteModal"
        ok-text="Delete"
        title="Delete Legal Signer"
        @onOk="runDeleteLegalSignerRequest"
      >
        Please confirm you would like to delete this legal signer
      </cp-confirm-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CpCardHead from './card-head';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import fields from './fields';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import { legalSignerOptionsList } from '@/pages/securitize-id/components/options';

export default {
  name: 'SecuritizeIdLegalSignersTable',
  metaInfo: {
    title: 'Edit Investor',
  },
  components: {
    CpTable,
    CpCardHead,
    CpButton,
    CpConfirmModal,
  },
  props: {
    isUpdateTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields,
      urlParams: { investorId: this.$route.params.investorId },
      currentRound: {},
      editMode: false,
      tableData: [],
      selectedLegalSignerForDelete: null,
      legalSignerOptionsList,
    };
  },
  watch: {
    isUpdateTable(val) {
      if (val) {
        this.$refs.cpTable._updateTableData();
      }
    },
  },
  methods: {
    ...mapActions('securitizeId', ['deleteSecuritizeIdLegalSignerByInvestorId']),

    verifyDeleteInvestor(investorRowItem) {
      this.selectedLegalSignerForDelete = investorRowItem.signerId;
      this.$refs.cpConfirmDeleteModal.show();
    },
    runDeleteLegalSignerRequest() {
      const params = { investorId: this.urlParams.investorId, legalSignerId: this.selectedLegalSignerForDelete };
      this.deleteSecuritizeIdLegalSignerByInvestorId({ params })
        .then(() => {
          this.$refs.cpTable._updateTableData();
        });
    },
    getUboOwning(uboOwning) {
      return (this.legalSignerOptionsList.uboOptionsList.find(v => v.value === uboOwning) || {}).text || 'No';
    },
    getIsLegalSigner(isLegalSigner) {
      return (this.legalSignerOptionsList.isLegalSignerList.find(v => v.value === isLegalSigner) || {}).text || 'N/A';
    },
  },
};
</script>

<style lang="scss" scoped>
    .fundraise-round {
        .table {
            tr {
                td:nth-child(8), td:nth-child(9) {
                    white-space: nowrap;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .card-header {
            border-bottom: none;
        }
    }
</style>
