var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mb-5 fundraise-round"},[_c('cp-card-head',{attrs:{"card-name":"Key Parties","without-edit":"","create-elem":"","button-title":"Add","button-icon":"ios-add"},on:{"onCreateElem":function($event){return _vm.$emit('addLegalSignerModel')}}}),_c('cp-table',{ref:"cpTable",attrs:{"without-pagination":"","without-search":"","get-data-action":"securitizeId/getSecuritizeIdLegalSignersList","url-params":_vm.urlParams,"fields":_vm.fields},scopedSlots:_vm._u([{key:"isLegalSigner",fn:function(ref){
var item = ref.rowData.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getIsLegalSigner(item.isLegalSigner))+" ")])]}},{key:"isBeneficialOwner",fn:function(ref){
var item = ref.rowData.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getUboOwning(item.uboOwning))+" ")])]}},{key:"name",fn:function(ref){
var item = ref.rowData.item;
return [(item.signerType === 'entity')?_c('span',[_vm._v(_vm._s(item.legalName))]):_vm._e(),(item.signerType === 'individual')?_c('span',[_vm._v(_vm._s(item.individualName.firstName)+" "+_vm._s(item.individualName.lastName))]):_vm._e()]}},{key:"taxId",fn:function(ref){
var item = ref.rowData.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("nullToMinus")(item.taxId)))])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat"},on:{"click":function($event){return _vm.$emit('onViewElem', rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-eye"})]),_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat ml-3"},on:{"click":function($event){return _vm.verifyDeleteInvestor(rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-trash"})])],1),_c('div',{staticClass:"d-flex"})]}}])}),_c('cp-confirm-modal',{ref:"cpConfirmDeleteModal",attrs:{"ok-text":"Delete","title":"Delete Legal Signer"},on:{"onOk":_vm.runDeleteLegalSignerRequest}},[_vm._v(" Please confirm you would like to delete this legal signer ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }